@import 'assets/scss/config/_variables';

#header {
    height: 100px;

    .container {
        height: 100%;
        padding: 1rem 1.5rem;
        display: flex;
        justify-content: space-between;
    }

    .navbar-brand {
        .image {
            height: 100%;
            
            img {
                max-height: 100%;
                width: auto;
            }
        }
    }

    .navbar-burger {
        &:hover {
            background-color: rgba(245, 245, 245, 1);
            border: none;
        }

        &:focus {
            background-color: inherit;
            outline: none;
            border: none;
        }
    }

    .navbar-item {
        padding-left: 1.7rem;

        a {
            color: rgba(74, 74, 74, 1);
            font-size: 1.3em;
            font-weight: 700;

            &:hover {
                color: $primary-color;
            }
        }
    }

    .mobile-menu {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: rgba(54, 54, 54, 1);
        padding: 1rem 1.5rem;
        border-bottom: 2px solid $primary-color;

        li {
            padding-left: 0;
            
            a {
                color: rgba(245, 245, 245, 1);
            }
        }
    }
}

@media(max-width: 1023px) {
    #header {
        .navbar-brand {
            width: 100%;
            align-items: center;
        }
    }
}