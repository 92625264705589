#contact-form {
    &.columns {
        margin: -0.3rem;

        .column {
            padding: .6rem;
            position: relative;
        }
    }

    .input, .select > select, .textarea {
        background-color: rgba(247, 247, 247, 1)
    }

    .error {
        position: absolute;
        color: rgba(225, 0, 0, 1);
        font-size: .8em;
        width: 100%;
        text-align: right;
        padding-right: 1.5rem;
    }

    button {
        width: 100%;
    }
}

@media(min-width: 768px) {
    #contact-form {
        button {
            width: auto;
        }
    }
}