@import 'assets/scss/config/_variables';

#contact-branches {
    padding: 7rem 1.5rem;
    background-image: linear-gradient($contact-branches-background-color, $contact-branches-background-color), url('/img/workshop.jpg');
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    color: rgba(245, 245, 245, 1);

    h3 {
        font-size: 2.5em;
        font-weight: 700;
        margin-bottom: 0.5em;
    }

    p {
        font-size: 1.1em;
        margin-bottom: 0.5rem;
    }
}