#services {
    background-color: rgba(247, 247, 247, 1);

    .image {
        margin: 1rem 0 2rem;
    }

    p {
        margin-bottom: 1rem;
    }

    ul {
        padding-left: 1.5rem;
        list-style: disc;
        padding-bottom: 1rem;
    }
}