@import 'assets/scss/config/_variables';

#scroll-to-top {
    .to-top-button {
        position: fixed;
        left: 1rem;
        bottom: 1rem;
        height: 51px;
        width: 51px;
        background: url('/img/ui.totop.png') no-repeat left top;
        border: none;
        z-index: 10;

        &:active, &:focus {
            outline: 0;
        }
    }
}