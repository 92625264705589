@import 'assets/scss/config/_variables';

#gallery-grid {
    position: relative;
    overflow: hidden;

    .gallery-item {
        float: left;
        min-height: 1px;
        width: 100%;
        margin-top: 8px;
        padding-left: 4px;
        padding-right: 4px;
        overflow: hidden;

        .aspect {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;
            padding-bottom: 100%;

            &:hover {
                .details {
                    bottom: 0;
                }
            }
        }

        .aspect--inner {
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        img {
            display: block;
        }

        .details {
            background-color: $primary-color;
            color: rgba(245, 245, 245, 1);
            padding: 1rem;
            position: absolute;
            bottom: -210px;
            height: 210px;
            transition: bottom 0.25s ease-in;

            h4 {
                text-align: center;
                font-size: 1.2em;
                font-weight: 700;
                margin-bottom: 0.5rem;
            }

            p {
                font-size: 0.9em;
                margin-bottom: 1rem;
            }

            .specs {
                padding-left: 1.5rem;
                list-style: circle;
            }
        }
    }

    .sizer {
        width: 8.33333%
    }
}

@media(min-width:768px) and (max-width: 1023px) {
    #gallery-grid {
        .gallery-item {
            width: 50%;
        }
    }
}

@media(min-width: 1024px) {
    #gallery-grid {
        .gallery-item {
            width: 25%;
        }
    }
}