@import '../config/variables';

a {
    color: $primary-color;
    transition: color .5s ease;
}

.section {
    padding: 7rem 1.5rem;

    .section-title {
        font-size: 2.8em;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        position: relative;
        margin-bottom: 2rem;
        padding-bottom: 0.5rem;

        &:after {
            content: '';
            height: $section-title-underline-height;
            width: $section-title-underline-width;
            background-color: $primary-color;
            position: absolute;
            bottom: 0;
            left: calc(50% - #{$section-title-underline-width / 2});
        }
    }

    .section-text {
        text-align: center;
        display: flex;
        justify-content: center;
        margin-bottom: 3rem;

        p {
            max-width: 860px;
        }
    }
}

button.is-oiltraps {
    background-color: $primary-color;
    border-color: $primary-color;
    color: rgba(245, 245, 245, 1);

    &:active {
        border-color: $primary-color;
        color: rgba(245, 245, 245, 1);
    }

    &:hover {
        background-color: darken($primary-color, 5%);
        border-color: $primary-color;
        color: rgba(245, 245, 245, 1);
    }

    &:focus {
        border-color: lighten($primary-color, 5%);
        color: rgba(245, 245, 245, 1);

        &:not(:active) {
            box-shadow: 0 0 0 0.125em rgba($primary-color, 0.25);
        }
    }
}

input, select, textarea {
    &:active, &:focus {
        border-color: $primary-color !important;
        box-shadow: 0 0 0 0.125em rgba($primary-color, 0.25) !important;
    }
}