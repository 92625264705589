@import 'assets/scss/config/_variables';

#callout {
    h2 {
        font-size: 2.5em;
        text-align: center;
        text-transform: uppercase;

        &.highlight {
            color: $primary-color;
            font-weight: 700;
        }
    }

    .column {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image {
        img {
            width: 400px;
        }
    }
}

@media(min-width: 768px) {
    #callout {
        h2 {
            font-size: 3.6em;
            text-align: left;
        }
    }
}