@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Raleway:wght@400;700&display=swap');

* {
    font-family: 'Raleway', sans-serif;
}

h1, 
h2, 
h3, 
h4, 
h5, 
h6 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}