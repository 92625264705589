@import 'assets/scss/config/_variables';

#gallery-filter {
    width: 100%;
    display: none;

    li {
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }

    button {
        border: none;
        appearance: none;
        font-size: 1em;
        font-weight: 700;
        border: 1px solid rgba(0, 0, 0, 0.85);
        border-radius: 2px;
        padding: 5px 10px;

        &.active {
            background-color: $primary-color;
            border-color: $primary-color;
            color: rgba(245, 245, 245, 1);
        }

        &:hover {
            &:not(.active) {
                background-color: lighten($primary-color, 65%);
            }
        }

        &:focus {
            outline: none;
        }
    }
}

@media(min-width: 768px) {
    #gallery-filter {
        display: flex;
        justify-content: center;
    }
}