@import 'assets/scss/config/_variables';

#about {
    background-color: rgba(247, 247, 247, 1);

    .title {
        font-size: 1.3em;
        font-weight: 700;
        margin-bottom: 0.7rem;
        
        svg {
            color: $primary-color;
            margin-right: 1rem;
        }
    }
}